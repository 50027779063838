body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: #302e2e;
    overflow-x: hidden;
  }
  
  /* nav {
    margin-bottom: 2em;
  }
   */
  .logoImg {
    animation: logomation 0.8s ease-out;
  }
  
  @keyframes logomation {
    0% {
      transform: translateY(-20%);
      opacity: 0;
    }
  
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
    
  @keyframes searchPropMation {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  
  @keyframes RegLogMation {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  
  /* .errmsg {
    display: block;
    background: #000;
    padding: 0.5em 0em;
    width: 55%;
    border-radius: 5px;
    color: red;
    text-align: center;
  } */
  

  
  /* nav {
    padding: 1em 2em;
    text-align: center;
  }
  
  nav img {
    width: 18em;
    margin: 0 auto;
  } */

  .uploadprop {
    position: relative;
    background: #00000063;
    color: #fff;
    padding: 2em;
    margin: 0 auto;
    text-align: center;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-around;
    gap: 1em;
  }
  
  .uploadprop form {
    padding: 2em;
    border-radius: 5px;
    background: linear-gradient(120deg, rgba(0, 110, 255, 0.701), rgb(13, 175, 13));
  }
  
  .upload-property-section {
    display: flex;
    justify-items: center;
    gap: 1em;
  }
  
  .historySection {
    /* background: #000; */
  /* max-height: 30em; */

 
  }
.theBoxesParent{
  overflow-y: auto;
  background: #000;
  padding: 1em;
  border-radius: 5px;
  margin-top: 1em;
  max-height: 30em;
  width: 100%;
}
.theBoxesParent::-webkit-scrollbar{
  width: 10px;
}
.theBoxesParent::-webkit-scrollbar-thumb{
  border-radius: 5px;
  background: #f2ebeb;
}

  .theboxes {
    background: rgba(55, 55, 56, 0.858);
    border-radius: 5px;
    text-align: left;
    padding: 0.5em 1em;
    margin-bottom: 1em;
    position: relative;

  }
  .overlay{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    left: 0;
    background: transparent;
    opacity: 1;
    bottom: 0;
    top: 0;
    right: 0;
  }

  /* Add this to your CSS stylesheet */
/* Create a CSS Grid container for your items */
.theBoxesParent{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust column size as needed */
  gap: 20px; /* Adjust gap size as needed */
  overflow-x:hidden;

}

.theboxes {
  /* Your existing styles for individual items */
  transition: all 0.3s ease; /* Adjust the duration and easing as needed */
  border: 1px solid #ccc;
  padding: 10px;
}
.theboxes p span{
  font-weight: bold;
  /* font-size: 18px; */
}

.theboxes.deleted {
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateX(90px); /* Adjust as needed */
  height: 0; /* Set the height to 0 for removed items */
  padding: 0; /* Remove padding for removed items */
  border: none; /* Remove border for removed items */
}


  .overlay:hover{
    opacity: 1;
  }
  .deleteConntainer{
    background-color: rgba(240, 241, 242, 0.641);
    color: rgb(0, 0, 0);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4em;
    border-radius: 50%;
    height: 4em;
  }
  .deleteConntainer:hover{
    background: #000;
    color: #fff;
  }
  
  .logOutBtn,
  .submitBtn {
    background: #000;
    color: #fff;
    padding: 0.5em 3em;
    outline: none;
    border: none;
    border-radius: 5pc;
    margin: 0 0 10px 0;
  }
  
  .logOutBtn {
    margin-left: 5px;
  }
  
  .submitBtn {
    margin-left: 5px;
  }
  
  .submitBtn:hover,
  .logOutBtn:hover {
    transition: all 0.5s ease-out;
    background: #fff;
    color: #000;
    /* background: linear-gradient(120deg, rgba(0, 110, 255, 0.701), rgb(13, 175, 13));  */
  }
  
  /* .show {
    display: block;
    text-align: left;
  } */
  
  /* .hide {
    display: none;
  } */
  .g{

    background-color: black;
    padding: 1em;
    border-radius: 5px;
  }
  .upload-property-section select {
    padding: 0.5em 1em;
    margin: 5px 0;
    width: 100%;
    border-radius: 5px;
    outline: none;
    border: none;
  }
  
  .upload-property-section input {
    padding: 0.5em;
    border-radius: 5px;
    outline: none;
    border: none;
    margin: 5px 0;
    background: #f2f0f0;
  }
  
  .landFlex-after-loggedin {
    display: flex;
    flex-direction: column;
  }
  

  
  
  .budget-container-after-loggedin {
    text-align: left;
    font-weight: bold;
  }
  
  .budget-container-after-loggedin input {
    width: 20%;
    margin-left: 1em;
  
  }
  .column1-after-loggedin {
    display: flex;
    /* width: 100%; */
    flex-direction: column;
    /* grid-template-columns: 1fr; */
    font-size: 12px;
  }
  .extras-container-after-loggedin,
  .column1-after-loggedin {
    background: #f3f1f1b0;
    color: #000;
    border-radius: 5px;
    padding: 1em;
  }
  
  .pets-container-after-loggedin,
  .garden-container-after-loggedin {
    display: flex;
  }
  
  .pets-container-after-loggedin P,
  .garden-container-after-loggedin P {
    margin-right: 5px;
  }
  
  .yes-no-container-after-loggedin {
    display: flex;
    margin-left: 5px;
  }
  
  
  .time-container-after-loggedin {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;
    /* background: #000; */
    text-align: left;
  }
  
  .time-container-after-loggedin p {
    /* font-size: 12px; */
    margin: 5px 0;
  }
  
  .time-container-after-loggedin input {
    width: 30%;
  }
  
  footer {
    text-align: center;
    color: #fff;
  
  }
  
  
  
  
  /* Before Logged CSS */
  
  
/*   
  .errmsg {
    margin: 0 auto;
  } */
  
