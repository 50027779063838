@media(min-width:600px) {
  #searchform .search-property-section {
    display: flex;
    justify-content: center;
  }
.contact-info-email{
  /* background: #000; */
  text-align: left;
}
.contact-you-dropdown{
  /* background: #000; */
  text-align: left;
}
.contact-you-dropdown select{
width: 18%;
}
.contact-info-email input{
  width: 49%;
}
  .divSamples,
  .otherContent,
  .col2,
  .signInAndSignUpSection {
    width: 50%;
  }
  .requestPropertySection {
    width: 80%;
  }
.needAnAccountSection{
  /* background-color: red; */
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}
  .BodyContentColumn {
    display: flex;
    flex-direction: row;
    /* background-color: orange; */

    margin-bottom: 1em;
  }

  .divSamples,
  .otherContent {
    margin-left:1em;
    width: 50%;
  }

  .requestPropertySection #searchform .search-property-section {
    display: flex;
    flex-direction: column;
  }
  /* .requestPropertySection #searchform .search-property-section .column1 */
  .budget-container label,
  .inspection-container {
    text-align: left;
  }
 
  .budget-container {
    /* background: #000; */
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .budget-container input{
    width: 40%;
  }

 

  .inspection-container {
    /* padding-left: 1em; */
    /* display: flex; */
    /* justify-content: space-around; */
    /* background: #000; */
    /* width: 40%; */
  }

  .inspection-container div {
    /* width: 30%; */
    /* background: #000; */
  }

  .availableForInspection {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* background: #000; */
  }

  .time-container {
    display: flex;
    align-items: center;
 
  }
  .time-container input{
  width: 100%;
}

.budget-container input,.time-container input {
    margin-left: 0.5em;
  }

  .section2 {
    display: flex;
    flex-direction: row-reverse;
    /* justify-content: space-between; */
    /* background-color: orange; */
    margin-bottom: 1em;
  }

  .signin-other {
    margin: 0 1em 0 0;
  }
  .LGA{
    margin-bottom: 0;
      max-height: 15%;
  min-height: 15%;
  }

  /*col-row */
  .col-row {
    /* background-color: red; */
    /* margin: 1em; */
    display: flex;
    justify-content: center;
  }
  
  
}

@media(max-width:900px) {

  .divSamples,
  .otherContent,
  .col2,
  .signInAndSignUpSection,
  .requestPropertySection {
    width: 100%;
  }

  .BodyContentColumn {
    display: flex;
    flex-direction: column;
    /* background-color: orange; */
    margin-bottom: 1em;
  }

  .divSamples,
  .otherContent {
    margin: 0 0 0 0em;
    /* width: 50%; */
  }
}
@media (max-width:550px) {
  body{
    /* background-color: red; */
  }

.col-a{
  flex-direction:column;
  /* justify-content: center; */
  /* background: #000; */
}
.time-container,.budget-container {
  /* width: 100%; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-direction: row;
  /* background: #000; */

}
.availableForInspection{
  text-align: left;
}
.time-container input,.budget-container input{
  width: 30%;
  margin-left: 0.5em;
}
/* .divSamples{
  height: 70vh;background-size: cover;
  
} */
.signin-other  div{
  flex-direction: column;
  row-gap: 1em;
}
/* .signin-other{background-color: red;} */
.signin-other img{
  width: 100%!important;
}
}
@media (max-width:375px) {

  .BodyContentColumn{
    padding: 0.5em;
  }
  .uploadprop{
    padding: 0.5em!important;
  }
}

@media (max-width:475px){

  #signIn input{
    width: 60%;
  }
}

@media (max-width:320px){
 .formButtonsSection{
  padding:0 1em;
 }


}
@media (max-width:970px){
  .uploadprop {
    flex-direction: column;
  }
  .historySection{
    width: 100%!important;
  }
 }

 @media (max-width:761px){
  .uploadprop {
    flex-direction: column;
  }
  .upload-property-section {
    flex-direction: column;
  }
 }

 @media (max-width:498px){
  .uploadprop form{
    padding: 1em!important;
  }
 }
 @media(max-width:400px){
  #searchform {
    padding:0;
   }
 }