
.loader{
    width: 40px;
    height: 40px;
    border: 8px solid black;
    border-top: 8px solid rgb(4, 250, 25);
    border-radius: 50%;
    animation: spin 2s linear infinite;
    /* margin: 0 auto; */
}
#signIn,#register{
    position: relative;
}
.loadingParentContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #ffffffb3;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* height: 100%; */
    /* width: 100%; */
}

@keyframes spin {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.Logoutloading{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.726);
    /* height: 10em; */
    left: 0;
    right: 0;
    margin: 0;
    top: 0;
    bottom: 0;
}