.Verification-Container{
    background-color: white;
    font-family: Arial, Helvetica, sans-serif;
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:1em;
    align-items: center;
    padding: 2em;
    border-radius: 5px;
    position: absolute;
    top: 20%;
    right: 30%;
    bottom: 50%;
    left: 30%;
    justify-self: center;
    align-self: center;
}
.verifyButton{
    background: blue;
    border:none;
    outline:none;
    border-radius: 5px;
    padding: 0.5em 4em;
    font-weight: bold;
}
.Verification-Container p{
    text-align: center;
}
@media (max-width:600px) {
    .Verification-Container{
        right: 5%;
        left: 5%;
    }
}