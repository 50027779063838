/* @tailwind base;
@tailwind components;
@tailwind utilities; */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap'); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
}

/* Body */
body {
  overflow-x: hidden;
  background: #000000ce;
}

/* nav*/
nav {
  margin-top: 1em;
  text-align: center;
}

nav .logoImg {
  width: 16em;
}

/* Heading Messsage */
.headerMessage {
  color: #fff;
  text-align: center;
}

/* Body Content */
.bodyContent {}

.BodyContentColumn {
  width: 100%;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
}

.AppfirstColumn {
  background: #0447ff;
}

.section2 {
  background: green;
}

.section3 {
  background-color: #0447ff;
}
.wer{
  /* background: red; */
  width: 100%;
}
.ValidEmailContactInfo{
  margin-top: -8px;
  color: yellow;
  background: #000;
}

/* requestPropertySection */
.requestPropertySection,
.divSamples,
.signInAndSignUpSection,
.col2 {
  background-color: rgba(0, 0, 0, 0.606);
  padding: 1em;
  /* margin: 0 0 1em 0; */
  border-radius: 5px;

}

/* Messages */
.errmsg,
.successmsg {
  /* text-align: center; */
  margin: 0 auto;
  margin-top: 0.5em;
  display: block;
  background: #000;
  padding: 0.5em 0em;
  width: 55%;
  border-radius: 5px;
  text-align: center;
}

.errmsg {
  color: red;
}

.successmsg {
  color: green;
}


.search-success-msg {
  margin: 0 auto;
  margin-top: 0.5em;
  display: block;
  background: #ffffff;
  padding: 0.5em 1em;
  width: 100%;
  border-radius: 5px;
  font-weight: bold;
  color: green;
  text-align: center;
}

#searchform {
  padding: 1em;
}

#searchform .search-property-section .column1 {
  background: #ffffffab;
  display: grid;
  margin: 0 2% 2% 2%;
  padding: 1em;
  border-radius: 5px;
}

#searchform .search-property-section .column1 select,
#searchform .search-property-section .column1 input,
.extras-container input {
  outline: none;
  border: none;
  margin-bottom: 1em;
  padding: 0.5em;
  border-radius: 5px;
}

/* Budget container*/
.budget-container {
  display: flex;
  flex-direction: column;
}

.budget-container label,
.inspection-container p {
  margin-bottom: 1em;
  /* font-weight: bold; */
}

/* Extras */
.extras-container h1 {
  margin-bottom: 0.2em;
}
.petinput{
  background: #3d3c3c26;
  margin-bottom: 5px;
}
.PetCheckContainer{
  /* background-color: red; */
  display: flex;
  align-items: center;
  gap: 5px;
}
.PetCheckContainer p {
  margin-top: 5px;
}
.extras-container .pets-container,
.extras-container .garden-container {
  display: flex;
  /* justify-content: center; */
}

.extras-container .pets-container .yes-no-container,
.extras-container .garden-container .yes-no-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin: 0 0.5em;

}

.extras-container .pets-container .yes-no-container p,
.extras-container .garden-container .yes-no-container p {
  margin-right: 13%;
}

.extras-container .pets-container .yes-no-container input,
.extras-container .garden-container .yes-no-container input {
  margin-top: 5%;
}

/* Submit Btn */
.submitBtn {
  padding: 0.5em 1em;
  outline: none;
  border: none;
  border-radius: 5px;
}

.submitBtn:hover {
  background: #000;
  color: #fff;
}

/* Other content */
.otherContent {
  background-color: rgba(0, 0, 0, 0.608);
  padding: 1em;
  /* margin: 1em 0 0 0; */
  border-radius: 5px;
  /* height: 40vh; */
}

.divSamples {
  /* background-color: #1300a2 */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* row-gap: 1em; */
}

.divSamples img,
.signin-other img,
.withAgent img {
  border-radius: 5px;
  width: 100%;
}



.withAgent div {
  /* background: red; */
  /* padding: 1em; */
  display: flex;
  /* gap: 1em; */
  /* width: 100%; */
  align-items: center;
  justify-content: space-between;
}

.withAgent div img {
  width: 48%;
}

/* .signin-other  div .first-image,.withAgent div img{
    height: 100%;
    min-height: 100%;
  } */
.divSamples h1,
.divSamples p,
.signin-other h1,
.signin-other p {
  margin-top: 1em;
  align-self: flex-start;
  color: #fff;
}

.withAgentTextCont {
  flex-direction: column;
}

.withAgentTextCont h1 {
  margin: 1em 0;
  align-self: flex-start;
}

.withAgentTextCont {
  margin: 0;
  /* background-color: red; */
}

.withAgent {
  /* height: 68vh; */
  /* background: url(./images/rent4.jpg),rgba(0, 0, 0, 0.348); */
  /* background-repeat: no-repeat; */
  /* background-blend-mode: darken; */
  /* background-size: contain; */
}

/* SignIn And Register */

#signIn,
#register,
#agent {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

#signIn {
  justify-content: center;
  align-items: center;
}

#signIn input {
  width: 40%;

}

#signIn input,
#register input,
#agent input {
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0.5em 1em;
  margin: 0.5em;
}

/* From Heading Text */
.headingTexts {
  text-align: center;
  color: white;
}

.headingTexts h3 {
  margin: 0.5em 0;
}

.headingTexts p {
  border: 1px solid #fff;
  margin: 0 auto;
  margin-bottom: 0.5em;
  width: 50%;
}

/* formButtonsSection */

.formButtonsSection {
  /* background: #000; */
  margin: 0.5em 0 1em 0;
  width: 100%;
  /* display: flex; */
  column-gap: 0.5em;
  justify-content: center;
  align-items: center;
}

.formButtonsSection button,
.agentApplyNowBtnSection button {
  padding: 0.5em;
  outline: none;
  border: none;
  border-radius: 5px;
}

.formButtonsSection button:hover,
.agentApplyNowBtnSection button:hover {
  background: #000;
  color: white;
}


.agentApplyNowBtnSection {
  margin: 0.5em 0;
}

/* Background for forms */

#signIn,
#register,
#agent,
#searchform {
  text-align: center;
  background: linear-gradient(120deg, rgba(0, 110, 255, 0.701), rgb(13, 175, 13));
}

#searchform {
  border-radius: 5px;
}

/* Instructions Pop up */
.instructions {
  color: rgb(228, 228, 57);
  padding: 1em;
  width: 55%;
  text-align: left;
  margin: 0 auto;
  font-size: 12px;
  border-radius: 5px;
  background-color: rgb(0, 0, 0);
}


/* Input Container */
.inputContainer {
  position: relative;
  /* background-color: red; */
  /* border: 2px solid #fff; */
}

.validandInvalidContainer {
  position: relative;
  /* background-color:blue; */
}

/* offscreen */
.offscreen {
  display: none;
}

.in {
  position: relative;
  top: 70%;
  right: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #000; */
  /* width: 2em; */
  /* height: 2em; */
}

/* Invalid and valid */
.valid {
  display: none;
  color: green;
}

.invalid {
  color: red;
  display: none;
}

.invalid,
.valid {
  position: absolute;
  /* top: 10%; */
  /* right: 40%; */
  /* max-width: 10%; */
  display: none;

}

/* Show and Hide */
.show {
  display: block;
  text-align: left;
}

.hide {
  display: none;
}

/* footer */
footer {
  text-align: center;
  color: #fff;

}

.col-a {
  display: flex;
  column-gap: 1em;
  justify-content: center;
  /* background: #000; */
}

.col-for-available-for,
.col-for-property-purpose {
  /* background-color: #a20000; */
  width: 100%;

}

.col-for-available-for select,
.col-for-property-purpose select {
  width: 100%;
}

.LGA-State-Cont input,
.gh .nearest-bus input {
  width: 100%;
}

.gh .nearest-bus,
.availableForInspection {
  /* background-color: red; */
  width: 100%;
}

.availableForInspection p {
  text-align: left;
}

.gh,
.gt {
  /* display: flex; */
  /* background: pink; */
  width: 100%;
  align-items: center;
}

.budgetsection {
  /* background: #ff0000; */
  width: 100%;
  /* display: flex; */
  /* justify-content: space-around; */
}

.col-d {
  /* background: #ff0000; */
  width: 100%;
}

.withAgent {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  color: white;
}

.signin-other h1 {
  align-self: flex-start;
}